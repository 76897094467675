
export default {
  name: 'BoxContentImagesSlice',
  props: ['slice'],
  computed: {
    imagesCount() {
      return this.slice.items.length
    }
  }
}
